const tokenName = 'cat'
const adminTokenName = 'acat'

function getCookie() {
  const cookie =
    window.$cookies.get(tokenName) || localStorage.getItem(tokenName)
  return cookie ? JSON.parse(atob(cookie)) : null
}

function getAdminCookie() {
  const cookie =
    window.$cookies.get(adminTokenName) || localStorage.getItem(adminTokenName)
  return cookie ? JSON.parse(atob(cookie)) : null
}

export const adminMode = () => {
  const cookie = getAdminCookie()
  if (cookie) {
    return cookie.role === 'admin'
  }
  return null
}

export const fetchCat = () => {
  return getCookie()
}

export const fetchAdminCat = () => {
  return getAdminCookie()
}

export const getGeneralToken = () => {
  const cat = window.$cookies.get(tokenName) || localStorage.getItem(tokenName)
  const acat =
    window.$cookies.get(adminTokenName) || localStorage.getItem(adminTokenName)
  return cat || acat
}

export const getAdminToken = () => {
  const acat =
    window.$cookies.get(adminTokenName) || localStorage.getItem(adminTokenName)
  return acat
}

export const getToken = () => {
  const cat = window.$cookies.get(tokenName) || localStorage.getItem(tokenName)
  return cat
}

export const setToken = (token: string) => {
  window.localStorage.setItem(tokenName, token)
  window.$cookies.set(tokenName, token)
}

export const removeToken = () => {
  window.localStorage.removeItem(tokenName)
  window.$cookies.remove(tokenName)
}

export const removeAllTokens = () => {
  window.localStorage.removeItem(tokenName)
  window.$cookies.remove(tokenName)
  window.localStorage.removeItem(adminTokenName)
  window.$cookies.remove(adminTokenName)
}

export const setAdminToken = (token: string) => {
  window.localStorage.setItem(adminTokenName, token)
  window.$cookies.set(adminTokenName, token)
}

export const removeAdminToken = () => {
  window.localStorage.removeItem(adminTokenName)
  window.$cookies.remove(adminTokenName)
}
