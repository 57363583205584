<template>
  <div v-if="isPortalActive && !newMobileScreens && source !== SOURCES.communities">
    <div class="w-full bg-blue-600 text-center text-base font-semibold text-white py-3"
      v-if="isAdmin && !isBuilder && !inIframe">
      You are previewing in admin mode
    </div>
    <UIContentWrap>
      <NavBar v-if="isPortalActive && !newMobileScreens" :routes="routes" :isHome="true" @toggle="handleToggle"
        @logout="signOut" :backgroundImage="isBackgroundColor(headerSectionStyling)
          ? ''
          : replaceBucketUrlWithCdnUrl(headerSectionStyling.background)
          " :position="getNavbarAlignment" :opacityImg="headerSectionStyling.opacity ? headerSectionStyling.opacity : ''
            " :class="{ main: true }" :style="{
              'background-color': isBackgroundColor(headerSectionStyling)
                ? headerSectionStyling.background
                : '',
              'box-shadow': '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
              position: 'absolute',
              top: isAdmin && !isBuilder ? '48px' : '',
            }">
        <template #filter-left>
          <div class="flex items-center brand-logo-container justify-between w-full md:w-auto">
            <router-link v-if="showLogo" id="brandLogo" :to="{ name: libraryPath }">
              <img v-if="brandLogoUrl" class="brand-logo h-6 w-auto sm:h-6"
                :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)" alt="Logo" />
              <Home05Icon v-else class="h-5 w-5 font-semibold" />
            </router-link>
          </div>
        </template>
        <template #filter-right>
          <div class="flex items-center cursor-pointer" @click="openModal">
            <UIButton class="h-10 w-10" id="search-icon" :quaternary="true" :circle="true">
              <BaseIcon name="search" hwClass="h-4 w-4 text-gray-500" />
            </UIButton>
          </div>
        </template>
      </NavBar>
    </UIContentWrap>
  </div>
  <div v-else :class="{
    'sticky top-0 z-20 min-h-12': newMobileScreens,
  }">
    <MobileTopBarNavigationV2 v-if="newMobileScreens && isPortalActive && !isBuilder" :backCallback="backCallback" />
    <div v-else>
      <div class="w-full bg-blue-600 text-center text-base font-semibold text-white py-3" v-if="isAdmin && !isBuilder">
        You are previewing in admin mode
      </div>
      <div id="navigation-header" :class="[getThemeClasses(headerRoot, routeName), 'py-1']"
        class="px-4 sm:px-6 lg:px-8 shadow bg-white">
        <nav class="flex md:grid md:grid-cols-3 items-center py-2" :class="[
          `justify-${alignment}`,
          getThemeClasses(headerContainer, routeName),
        ]">
          <div v-if="source !== SOURCES.communities"
            class="flex items-center brand-logo-container justify-between w-full md:w-auto">
            <router-link v-if="showLogo" id="brandLogo" :to="{ name: libraryPath }">
              <img v-if="brandLogoUrl" class="brand-logo h-6 w-auto sm:h-6"
                :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)" alt="Logo" />
              <Home05Icon v-else class="h-5 w-5 font-semibold" />
            </router-link>
            <div class="mr-2 flex items-center md:hidden">
              <div class="flex items-center mr-2 md:mr-0" @click="handleSearchDrawer">
                <BaseIcon name="search" hwClass="w-4 h-4" />
              </div>
              <BookOpen01Icon v-if="(isPostDetailsPath || isAssignmentOrQuizPath)"
                class="curriculum-drawer h-5 w-5 mx-2 clickable" @click="handleOpenCurriculumComments" />
              <button id="openMobileMenu" @click="open = true" type="button"
                class="inline-flex items-center justify-center rounded-md  hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                <BaseIcon name="hamburger" hwClass="w-6 h-6 md:w-3 md:h-3" />
              </button>
            </div>
          </div>
          <div v-else>
            <UIButton id="back__btn" :text="true" @click="onClickBack">
              <div class="flex items-center text-primary-600 pt-1.5">
                <ChevronLeftIcon class="h-6 w-6 text-black" />
                <UITextMdMedium class="text-black">Back</UITextMdMedium>
              </div>
            </UIButton>
          </div>

          <div v-if="source !== SOURCES.communities"
            class="hidden md:flex justify-end items-center md:ml-10 md:pr-4 col-start-3">
            <router-link v-if="!newMobileScreens && isPortalActive" id="library" :to="{ name: libraryPath }"
              class="font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out">
              <div class="flex items-center cursor-pointer">
                <UIButton class="h-10 w-10" id="search-icon" :quaternary="true" :circle="true">
                  <DotsGridIcon class="h-5 w-5" />
                </UIButton>
              </div>
            </router-link>

            <div class="flex items-center cursor-pointer" @click="openModal">
              <UIButton class="h-10 w-10" id="search-icon" :quaternary="true" :circle="true">
                <BaseIcon name="search" hwClass="w-4 h-4" />
              </UIButton>
            </div>

            <div id="settings" class="ml-2 relative" x-data="{ open: false }">
              <div>
                <button @click="openSettings = !openSettings"
                  class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out">
                  <img
                    class="aspect-square object-cover w-8 rounded-full transition duration-150 ease-in-out hover:shadow-md hover:ring-2 hover:ring-nova-blue-600"
                    :src="userImage" alt="Instructor" />
                </button>
              </div>
              <div id="settings-menu" v-if="openSettings"
                class="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                <div class="py-1 rounded-md bg-white shadow-xs">
                  <router-link v-if="!isAdmin" :to="{ name: 'profile-settings' }"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Settings</router-link>
                  <div v-else @click="redirectTo('settings/profile')"
                    class="cursor-pointer hover:underline px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                    Settings
                  </div>
                  <button v-if="
                    !isAdmin && pwaAppSettings.pwaEnabled && !isPWAInstalled
                  " @click="triggerPWAEvent"
                    class="block w-full text-left font-semibold px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-nova-blue-600 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
                    Install APP
                  </button>

                  <a @click="signOut" href="#"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Log
                    out</a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div id="mobile-settings-menu" v-if="open"
          class="z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div class="rounded-lg shadow-md">
            <div class="rounded-lg bg-white shadow-xs overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div class="mobile-brand-logo-container">
                  <img v-if="brandLogoUrl" class="brand-logo h-10 w-auto"
                    :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)" alt="brand-logo" />
                  <p class="font-semibold" v-else>Your Logo</p>
                </div>
                <div class="-mr-2">
                  <button id="closeMobileMenu" @click="open = false" type="button"
                    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                    <BaseIcon name="cross" />
                  </button>
                </div>
              </div>
              <div class="px-2 pt-2 pb-3">
                <router-link :to="{ name: libraryPath }"
                  class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Library</router-link>
                <router-link v-if="!isAdmin" :to="{ name: 'profile-settings' }"
                  class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">Settings</router-link>
              </div>
              <div>
                <a @click="signOut" href="#"
                  class="block w-full px-5 py-3 text-base font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out">Log
                  Out</a>
              </div>
            </div>
          </div>
        </div>
        <PWAAlertModal :open="showPWAAlert" @close="closePWAAlert" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import PWAAlertModal from '@/components/common/PWAAlertModal.vue'
import UserAvatar from '@/components/common/UserAvatar.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import {
  getThemeClasses,
  inIframe,
  isAppInstalled,
  isClientPortal,
  redirectTo,
} from '@/helper'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { adminMode, removeAllTokens } from '@/helper/permission.helper'
import { NavBar } from '@gohighlevel/clientportal-core'
import {
  Bell01Icon,
  BookOpen01Icon,
  ChevronLeftIcon,
  DotsGridIcon,
  Home05Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIButton, UIContentWrap, UITextMdMedium } from '@gohighlevel/ghl-ui'
import firebase from 'firebase'
import 'firebase/auth'
import { defineComponent } from 'vue'
import {
  SOURCES,
  userAvatarImage
} from '../../helper/constants'
import MobileTopBarNavigationV2 from '../mobile/MobileTopBarNavigationV2.vue'

export default defineComponent({
  components: {
    UserAvatar,
    BaseIcon,
    ChevronLeftIcon,
    PWAAlertModal,
    UIButton,
    UITextMdMedium,
    NavBar,
    UIContentWrap,
    Home05Icon,
    Bell01Icon,
    DotsGridIcon,
    MobileTopBarNavigationV2,
    BookOpen01Icon
  },
  props: {
    logo: {
      type: String,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    alignment: {
      type: String,
      default: 'end',
    },
    backCallback: {
      type: Function,
    },
    coursesTypes: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: String,
      default: '',
    },
    sections: {
      type: Object,
      default: () => ({}),
    },
    lessonSections: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      open: false,
      openSettings: false,
      headerRoot: {
        'product-overview': 'navigation-header',
        'post-overview': 'lesson-navigation-header',
      },
      headerContainer: {
        'product-overview': 'header',
        'post-overview': 'lesson-header',
      },
      showPWAAlert: false,
      appLauncherIsOpen: false,
      clientProfileCardIsOpen: false,
      source: this.$route.query.source,
      groupSlug: this.$route.query.group_slug,
      SOURCES: SOURCES,
      routes: window.location.pathname.includes('/library')
        ? [
          {
            name: 'All Courses',
            routeName: ``,
            current: this.active
              ? this.active === 'All Courses'
                ? true
                : false
              : true,
          },
          {
            name: 'My Courses',
            routeName: ``,
            current: this.active === 'My Courses' ? true : false,
          },
        ]
        : [],
    }
  },
  computed: {
    isPostDetailsPath() {
      const postDetailsPath = ['post-overview', 'post-overview-v2']
      return postDetailsPath.includes(this.$route.name)
    },
    isAssignmentOrQuizPath() {
      const assignmentDetailsPath = [
        'mobile-assignment',
        'mobile-assignment-v2',
        'mobile-assignment-result',
        'mobile-assignment-result-v2',
        'mobile-assignment-overview',
        'mobile-assignment-overview-v2',
      ]
      const quizDetailsPath = [
        'mobile-quiz-result',
        'mobile-quiz-result-v2',
        'mobile-quiz-questions-v2',
        'mobile-quiz-questions',
        'mobile-quiz-overview',
        'mobile-quiz-overview-v2',
      ]
      return (
        assignmentDetailsPath.includes(this.$route.name) ||
        quizDetailsPath.includes(this.$route.name)
      )
    },
    device(): string {
      return this.$store.getters.device
    },
    brandLogoUrl(): string {
      return this.logo || this.$store.state.logoUrl
    },
    userImage(): string {
      return this.$store.getters.userAvatar || userAvatarImage
    },
    isAdmin() {
      return adminMode()
    },
    inIframe() {
      return inIframe()
    },
    libraryPath(): string {
      return 'library-v2'
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    locationId(): string {
      return this.$store.getters.locationId
    },
    pwaAppSettings(): any {
      return this.$store.getters.pwaAppSettings
    },
    routeName(): string {
      return this.$route.name
    },
    isPWAInstalled() {
      return isAppInstalled()
    },
    isPortalActive() {
      return isClientPortal()
    },
    clientPortalUserData() {
      return this.$store.getters.clientPortalUserData
    },
    getNavbarAlignment() {
      if (this.alignment === 'left') return 'start'
      else if (this.alignment === 'between') return 'center'
      return this.alignment
    },

    headerSectionStyling() {
      if (this.$route.name?.includes('post-overview')) {
        return this.lessonSections
      } else if (this.$route.name?.includes('product-overview')) {
        return this.sections
      }
      return {}
    },
    getThemeClasses() {
      return getThemeClasses
    },
  },
  mounted() {
    this.capturePWAEvent()
  },
  methods: {
    handleOpenCurriculumComments() {
      this.$store.commit(
        'SET_CURRICULUM_COMMENTS_OPEN',
        !this.$store.getters.openCurriculumComments
      )
    },
    handleSearchDrawer() {
      this.$store.commit(
        'SET_SEARCH_DRAWER_OPEN',
        !this.$store.getters.openSearchDrawer
      )
    },
    toggleAppLauncher() {
      this.clientProfileCardIsOpen = false
      this.appLauncherIsOpen = !this.appLauncherIsOpen
    },
    onClickBack() {
      const communitiesBasePath = window.location.origin.endsWith(
        'gokollab.com'
      )
        ? '/'
        : '/communities/groups/'
      const targetURL = `${window.location.origin}${communitiesBasePath}${this.groupSlug}/learning`
      window.location.href = targetURL
      this.$store.commit('SET_COMMUNITIES_FLAG', '')
    },
    toggleProfileCard() {
      this.appLauncherIsOpen = false
      this.clientProfileCardIsOpen = !this.clientProfileCardIsOpen
    },
    signOut() {
      try {
        removeAllTokens()
        firebase
          .auth()
          .signOut()
          .then(() => {
            if (this.isPortalActive) return redirectTo('login')
            this.$router.push({ name: 'login' })
          })
      } catch (error) {
        console.error('Error while signing out --> ', error)
      }
    },
    openModal() {
      this.emitter.emit('openSearchModal', true)
    },
    capturePWAEvent() {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault()
        this.storePWAEvent(event)
      })
    },
    storePWAEvent(event) {
      this.$store.commit('SET_PWA_APP_SETTINGS', {
        addWithExisting: true,
        pwaEvent: event,
      })
    },
    updateActiveCourse(type: string) {
      this.$emit('changeActive', type)
    },
    triggerPWAEvent() {
      this.openSettings = false
      if (this.pwaAppSettings && this.pwaAppSettings.pwaEvent) {
        this.pwaAppSettings.pwaEvent.prompt()
        return
      }
      this.showPWAAlert = true
    },
    closePWAAlert() {
      this.showPWAAlert = false
    },
    redirectTo,
    replaceBucketUrlWithCdnUrl,
    handleToggle(item) {
      this.routes.forEach((route) => {
        if (route.name === item.name) {
          route.current = true
          this.$emit('changeActive', route.name)
        } else {
          route.current = false
        }
      })
    },
    isBackgroundColor(customSections) {
      if (
        (customSections && customSections?.background?.startsWith('#')) ||
        customSections?.background?.startsWith('rgb')
      ) {
        return true
      }
      return false
    },
  },
})
</script>
