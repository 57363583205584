const override = process.env.DB_OVERRIDE === 'true'

const firebaseStagingConfig = {
  apiKey: 'AIzaSyAbVVAyzRuw0Mx18DYczCehMsjWFCYX1Lo',
  authDomain: 'highlevel-staging.firebaseapp.com',
  databaseURL: 'https://highlevel-staging.firebaseio.com',
  projectId: 'highlevel-staging',
  storageBucket: 'highlevel-staging.appspot.com',
  membershipStorageBucket: 'revex-membership-staging',
  messagingSenderId: '85350210461',
  appId: '1:85350210461:web:d3728d34bf8bd6b5',
  cdnUrl: 'https://staging.cdn.apisystem.tech',
  assetsUrl: 'https://staging.cdn.apisystem.tech',
  oldCdnUrl: 'https://staging.cdn.msgsndr.com',
  membershipCdnUrl: 'https://staging.cdnmembership.apisystem.tech',
}

const firebaseProductionConfig = {
  apiKey: 'AIzaSyB_w3vXmsI7WeQtrIOkjR6xTRVN5uOieiE',
  authDomain: 'highlevel-backend.firebaseapp.com',
  databaseURL: 'https://highlevel-backend.firebaseio.com',
  projectId: 'highlevel-backend',
  storageBucket: 'highlevel-backend.appspot.com',
  membershipStorageBucket: 'revex-membership-production',
  messagingSenderId: '439472444885',
  cdnUrl: 'https://cdn.apisystem.tech',
  assetsUrl: 'https://staging.cdn.apisystem.tech',
  oldCdnUrl: 'https://cdn.msgsndr.com',
  membershipCdnUrl: 'https://cdn.courses.apisystem.tech',
}

const config: { [key: string]: any } = {
  development: {
    mode: 'dev',
    mainServerUrl: 'https://highlevel-staging.appspot.com',
    baseUrl: 'http://localhost:7000',
    newBaseUrl: 'https://staging.services.leadconnectorhq.com/membership',
    restApiUrl: 'https://staging.services.leadconnectorhq.com',
    transcodingURL: 'https://staging.content.apisystem.tech/hls/memberships',
    transcodingURLV2:
      'https://staging.content.apisystem.tech/hls/v2/memberships',
    firebase: override ? firebaseProductionConfig : firebaseStagingConfig,
    paymentBaseUrl: 'https://staging.services.leadconnectorhq.com/payments',
    clientPortalServerUrl:
      'https://staging.services.leadconnectorhq.com/clientclub',
  },
  staging: {
    mode: 'staging',
    mainServerUrl: 'https://highlevel-staging.appspot.com',
    baseUrl: 'https://membership-backend-dot-highlevel-staging.appspot.com',
    newBaseUrl: 'https://staging.services.leadconnectorhq.com/membership',
    restApiUrl: 'https://staging.services.leadconnectorhq.com',
    transcodingURL: 'https://staging.content.apisystem.tech/hls/memberships',
    transcodingURLV2:
      'https://staging.content.apisystem.tech/hls/v2/memberships',
    firebase: firebaseStagingConfig,
    paymentBaseUrl: 'https://staging.services.leadconnectorhq.com/payments',
    clientPortalServerUrl:
      'https://staging.services.leadconnectorhq.com/clientclub',
  },
  production: {
    mode: 'production',
    mainServerUrl: 'https://msgsndr.com',
    baseUrl: 'https://msgsndr.com/membership',
    newBaseUrl: 'https://services.leadconnectorhq.com/membership',
    restApiUrl: 'https://services.leadconnectorhq.com',
    transcodingURL: 'https://content.apisystem.tech/hls/memberships',
    transcodingURLV2: 'https://content.apisystem.tech/hls/v2/memberships',
    firebase: firebaseProductionConfig,
    googleAnalyticId: 'UA-115177999-6',
    paymentBaseUrl: 'https://services.leadconnectorhq.com/payments',
    clientPortalServerUrl: 'https://services.leadconnectorhq.com/clientclub',
  },
}

export default config[process.env.NODE_ENV]
