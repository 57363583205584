<template>
  <div>
    <MobileLibraryV2 v-if="newMobileScreens" />
    <NeoLibrary v-else-if="!newMobileScreens && enableNeoTheme" />
    <div v-else id="user-library" class="w-full mb-2 h-screen">
      <div class="bg-white flex flex-col overflow-y-auto min-h-screen">
        <Navigation @changeActive="changeActive" :coursesTypes="coursesTypes" :active="active" />
        <div class="w-full flex items-center justify-center courses-font-family font-semibold mt-20"
          v-if="isPortalActive && !fetching && !allCourses.length">
          <UIEmpty id="empty-product-message" title="No courses found"
            description="You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey." />
        </div>
        <div v-else id="library-container" class="w-11/12 flex-1 flex flex-col xl:w-9/12 mx-auto my-4 p-4 lg:p-6">
          <div v-if="!isPortalActive" id="library-title" class="py-4 w-full text-left lg:px-4">
            <span class="font-semibold text-lg px-2 cursor-pointer" :class="active === coursesTypes.allCourses
              ? 'bg-nova-blue-600 py-1 rounded text-white'
              : ''
              " @click="changeActive(coursesTypes.allCourses)">All Courses</span>
            <span class="font-semibold text-lg px-2 cursor-pointer" :class="active === coursesTypes.myCourses
              ? 'bg-nova-blue-600 py-1 rounded text-white'
              : ''
              " @click="changeActive(coursesTypes.myCourses)">My Courses</span>
          </div>
          <LibraryShimmer v-if="fetching" />
          <div v-if="active === coursesTypes.allCourses && !fetching" id="product-list"
            class="my-4 mb-8 grid md:grid-cols-2 lg:grid-cols-3 row-gap-6 justify-center md:gap-8"
            :class="[isPortalActive ? 'pt-20' : '']">
            <template :key="course.id" v-for="course in allCourses">
              <UpsellBundle v-if="course.upsellType && course.offerUpSell" :offer="course.offerUpSell"
                :upsellId="course.id" :purchasedProductIds="purchasedProductIds" />
              <NewProductCard v-else :product-id="course.id" :product-title="course.title"
                :poster-image="course.posterImage" :product-description="course.description" :instructor-name="course.customizations && course.customizations.instructorName
                  " :instructor-headshot="course.customizations &&
                    course.customizations.instructorHeadshot
                    " :show-in-library="true" label="in-library" @handleClick="openProduct(course.id)" />
            </template>
          </div>
          <div v-if="active === coursesTypes.myCourses && !fetching" id="product-list"
            class="my-4 mb-8 grid md:grid-cols-2 lg:grid-cols-3 row-gap-6 justify-center md:gap-8"
            :class="[isPortalActive ? 'pt-20' : '']">
            <NewProductCard v-for="product in products" :key="product.id" :product-id="product.id"
              :product-title="product.title" :poster-image="product.posterImage"
              :product-description="product.description" :instructor-name="product.customizations && product.customizations.instructorName
                " :instructor-headshot="product.customizations &&
                  product.customizations.instructorHeadshot
                  " @handleClick="openProduct(product.id)" />
          </div>
        </div>
      </div>
      <UniversalSearch :show-modal="showSearchModal" @close="showSearchModal = false" />
    </div>
  </div>
</template>
<script lang="ts">
import LibraryShimmer from '@/components/common/LibraryShimmer.vue'
import Navigation from '@/components/common/Navigation.vue'
import UniversalSearch from '@/components/common/UniversalSearch.vue'
import NewProductCard from '@/components/product/NewProductCard.vue'
import UpsellBundle from '@/components/product/UpsellBundle.vue'
import { isClientPortal } from '@/helper'
import { LIBRARY_MENU_ITEMS } from '@/helper/constants'
import UpsellOffer from '@/models/UpsellOffer'
import { UserPurchaseService } from '@/services'
import NeoLibrary from '@/views/NeoLibrary.vue'
import { UIEmpty } from '@gohighlevel/ghl-ui'
import { defineComponent } from 'vue'
import Product from '../models/Product'
import MobileLibraryV2 from './mobile/MobileLibraryV2.vue'

export default defineComponent({
  props: {
    loadMyCourses: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UpsellBundle,
    Navigation,
    NewProductCard,
    LibraryShimmer,
    UniversalSearch,
    UIEmpty,
    MobileLibraryV2,
    NeoLibrary,
  },
  data() {
    return {
      products: [] as Product[],
      fetching: false,
      showSearchModal: false,
      upsellOffers: [] as UpsellOffer[],
      allCourses: [],
      purchasedProductIds: [],
      coursesTypes: {
        allCourses: LIBRARY_MENU_ITEMS.allCourses,
        myCourses: LIBRARY_MENU_ITEMS.myCourses,
      },
      active: this.$route.query.courses
        ? this.$route.query.courses === 'all'
          ? LIBRARY_MENU_ITEMS.allCourses
          : LIBRARY_MENU_ITEMS.myCourses
        : LIBRARY_MENU_ITEMS.allCourses,
    }
  },
  async mounted() {
    this.emitter.on('openSearchModal', this.openSearchModal)
    await this.fetchData()
  },
  computed: {
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isPortalActive() {
      return isClientPortal()
    },
    locationId(): string {
      return this.$store.getters.locationId
    },
    enableNeoTheme(): boolean {
      return true //this.$store.state.featureFlag?.enableNeoTheme
    },
  },
  methods: {
    /**
     * A function that fetches user products and upsell bundle.
     * Upsell bundle function is dependant on user products.
     * @return {Promise<void>} Promise that resolves after fetching user products and upsell bundle.
     */
    async fetchData(): Promise<void> {
      try {
        this.fetching = true
        await this.fetchUserProducts()
        await this.fetchUpsellBundle()
      } catch (error) {
        console.error('Error while fetching data --> ', error)
      } finally {
        this.fetching = false
      }
    },
    async fetchUserProducts() {
      try {
        this.products = await UserPurchaseService.getAllProducts()
        this.purchasedProductIds = this.products.map((p) => p.id)
      } catch (error) {
        console.error('Error while fetching products --> ', error)
      }
    },
    async fetchUpsellBundle() {
      try {
        const upsellOffers = await UserPurchaseService.getBundleOffers()
        const set = new Set()
        this.upsellOffers = upsellOffers.filter((offer) => {
          if (!set.has(offer.upsellOfferId)) {
            set.add(offer.upsellOfferId)
            return true
          }
          return false
        })
        this.allCourses = [...this.upsellOffers, ...this.products]
      } catch (error) {
        console.error('Error while fetching upsell bundles --> ', error)
      }
    },
    changeActive(value: string) {
      this.active = value
    },
    updateActiveLibrary(val: string) {
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', val)
      window.scrollTo(0, 0)
    },
    openProduct(productId) {
      this.$router.push({
        name: 'product-overview',
        params: { id: productId },
        query: this.$route.query,
      })
    },
    openSearchModal(value: boolean) {
      this.showSearchModal = value
    },
  },
})
</script>
