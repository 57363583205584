import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full text-center sm:text-left" }
const _hoisted_2 = { class: "w-11/12 flex items-center justify-start m-auto border-b border-[#EAECF0]" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = {
  key: 0,
  id: "searchList"
}
const _hoisted_5 = {
  key: 0,
  id: "optionList",
  class: "block py-2"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "p-2 text-left flex justify-between" }
const _hoisted_8 = { class: "text-gray-900 text-sm" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "px-4 py-2 flex justify-center items-center text-gray-700",
  ref: "loadMore"
}
const _hoisted_12 = { class: "inline-flex h-6 w-6" }
const _hoisted_13 = {
  key: 1,
  class: "block w-full mr-4 px-2 py-2 text-sm leading-5 text-gray-700"
}
const _hoisted_14 = { class: "font-semibold" }
const _hoisted_15 = {
  key: 1,
  class: "p-2 text-gray-700"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "py-1 px-2 text-left" }
const _hoisted_18 = {
  key: 2,
  class: "p-2 text-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSmIcon = _resolveComponent("SearchSmIcon")!
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchSmIcon, { class: "w-4 h-4 text-gray-500 m-4" }),
      _withDirectives(_createElementVNode("input", {
        id: "search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createDebounce())),
        "aria-label": "Search",
        name: "search-for-post",
        type: "text",
        maxlength: "64",
        class: "appearance-none rounded-none relative block w-full focus:outline-none placeholder-gray-600 text-gray-900 sm:text-base sm:leading-5 placeholder:text-base placeholder:leading-5",
        placeholder: "Search products, categories and lessons"
      }, null, 544), [
        [
          _vModelText,
          _ctx.searchText,
          void 0,
          { trim: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.searchText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.searchData.length || _ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchData, (option) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "searchList flex flex-col my-2 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-400 leading-5",
                      key: option.typeId,
                      onClick: _withModifiers(($event: any) => (_ctx.changeRoute(option)), ["prevent"])
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(option.title.length > 50
                  ? `${option.title.substring(0, 40)}...`
                  : option.title), 1),
                        _createElementVNode("img", {
                          class: "text-gray-700 h-5",
                          src: _ctx.searchIcons[option.type],
                          alt: "product icon"
                        }, null, 8, _hoisted_9)
                      ]),
                      (option.description)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            innerHTML: option.description,
                            class: "px-2 text-left text-xs h-auto"
                          }, null, 8, _hoisted_10))
                        : _createCommentVNode("", true),
                      (option.type !== 'product')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["product-title px-2 text-left font-semibold text-xs h-5", option.description ? 'p-2' : 'pb-2'])
                          }, _toDisplayString(_ctx.productTitleMapping[option.productId]), 3))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_6))
                  }), 128)),
                  (!_ctx.allDataLoaded || _ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, [
                          _createVNode(_component_UISpinner, { size: "small" })
                        ])
                      ], 512))
                    : _createCommentVNode("", true)
                ]))
              : (!_ctx.isSearchDataAvailable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _cache[2] || (_cache[2] = _createTextVNode(" No results for ")),
                    _createElementVNode("span", _hoisted_14, "\"" + _toDisplayString(_ctx.searchText) + "\"", 1),
                    _cache[3] || (_cache[3] = _createTextVNode(". Please try another search "))
                  ]))
                : _createCommentVNode("", true)
          ]))
        : (_ctx.recentSearches.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-left" }, "Recent Searches", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentSearches, (option, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "searchList flex flex-col my-2 pr-6 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-400 leading-5",
                  key: index,
                  onClick: _withModifiers(() => {
              _ctx.searchText = option
              _ctx.searchContent()
            }
            , ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(option), 1)
                ], 8, _hoisted_16))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_18, "No Recent Searches"))
    ])
  ]))
}