import 'firebase/auth'

import {
  getQueryString,
  inIframe,
  isClientPortal,
  redirect,
  redirectTo,
} from '@/helper'
import {
  fetchAdminCat,
  fetchCat,
  removeAllTokens,
  setAdminToken,
  setToken,
} from '@/helper/permission.helper'
import {
  initialize,
  saveContactId,
  saveFeatureFlag,
  saveUserId,
} from '@/services'
import LoginService from '@/services/LoginService'
import NewLibrary from '@/views/NewLibrary.vue'
import SecureRoutes from '@/views/SecureRoutes.vue'
import {
  DashBoard,
  NotificationMobileView,
  ProfileContainer,
} from '@gohighlevel/clientportal-core'
import axios from 'axios'
import firebase from 'firebase/app'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router'
import config from '../config'
import { deviceId, isMobileTablet } from '../helper/device_info'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */ '../views/PasswordRecovery.vue'
      ),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: () =>
      import(/* webpackChunkName: "SetPassword" */ '../views/SetPassword.vue'),
    meta: {
      requiresGuest: true,
    },
    props: (route) => ({
      contactId: route.query.contact_id,
      locationId: route.query.location_id,
      type: route.query.type,
    }),
  },
  {
    path: '/',
    component: SecureRoutes,
    children: [
      {
        path: 'library',
        name: 'library',
        component: () =>
          import(/* webpackChunkName: "Library" */ '../views/Library.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'library-v2',
        name: 'library-v2',
        component: NewLibrary,
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
        props: (route) => ({ courses: route.query.courses }),
      },
      {
        path: 'library-v2/my-courses',
        name: 'my-courses-library',
        component: () =>
          import(
            /* webpackChunkName: "NewLibrary" */ '../views/NewLibrary.vue'
          ),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'upsell-bundle/:offerId/:upsellId',
        name: 'upsell-bundle',
        component: () =>
          import(
            /* webpackChunkName: "MobileUpsellBundleV2" */ '../views/mobile/MobileUpsellBundleV2.vue'
          ),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '../views/settings/index.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'profile-settings',
            component: () =>
              import(
                /* webpackChunkName: "Profile" */ '../views/settings/Profile.vue'
              ),
          },
          {
            path: 'password',
            name: 'password-settings',
            component: () =>
              import(
                /* webpackChunkName: "Password" */ '../views/settings/Password.vue'
              ),
          },
          {
            path: 'purchase-history',
            name: 'purchase-history',
            component: () =>
              import(
                /* webpackChunkName: "PurchaseHistory" */ '../views/settings/PurchaseHistory.vue'
              ),
          },
        ],
      },
      {
        path: 'products',
        component: () =>
          import(
            /* webpackChunkName: "ProductContainer" */ '../views/ProductContainer.vue'
          ),
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
        children: [
          {
            path: ':id',
            name: 'product-overview',
            component: () =>
              import(
                /* webpackChunkName: "ProductsThemeController" */ '../views/ProductsThemeController.vue'
              ),
          },
          {
            path: ':id/categories',
            name: 'category-list',
            component: () =>
              import(
                /* webpackChunkName: "CategoryListV2" */ '../views/mobile/CategoryListV2.vue'
              ),
          },
          {
            path: ':id/categories/:category_id',
            name: 'category-overview',
            component: () =>
              import(
                /* webpackChunkName: "CategoryDetail" */ '../views/CategoryDetail.vue'
              ),
          },
          {
            path: ':id/categories/:category_id/posts/:post_id',
            name: 'post-overview',
            component: () =>
              import(/* webpackChunkName: "Post" */ '../views/Post.vue'),
            children: [
              {
                path: 'comments',
                name: 'post-comments',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileComments" */ '../components/comments/MobileComments.vue'
                  ),
              },
              {
                path: 'mobile-quiz-overview',
                name: 'mobile-quiz-overview',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileAssessmentInitiate" */ '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },
              {
                path: 'mobile-quiz-overview/:quiz_id',
                name: 'mobile-quiz-questions',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileQuizV2" */ '../views/mobile/MobileQuizV2.vue'
                  ),
              },
              {
                path: 'quiz-result/:quiz_id/',
                name: 'mobile-quiz-result',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileQuizResult" */ '../components/assessments/MobileQuizResult.vue'
                  ),
              },

              {
                path: 'mobile-assignment',
                name: 'mobile-assignment',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileAssessmentInitiate" */ '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },
              {
                path: 'mobile-assignment-overview',
                name: 'mobile-assignment-overview',
                component: () =>
                  import(
                    /* webpackChunkName: "Assignment" */ '../views/Assignment.vue'
                  ),
              },
              {
                path: 'mobile-assignment-result',
                name: 'mobile-assignment-result',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileAssignmentResult" */ '../components/assessments/MobileAssignmentResult.vue'
                  ),
              },
              {
                path: 'v2/assignment',
                name: 'new-assignment-overview',
                component: () =>
                  import(
                    /* webpackChunkName: "AssignmentAttempt" */ '../components/neo-classic-theme/assignment/AssignmentAttempt.vue'
                  ),
              },
              {
                path: 'v2/quiz',
                name: 'new-quiz-overview',
                component: () =>
                  import(
                    /* webpackChunkName: "QuizAttempt" */ '@/components/neo-classic-theme/quiz/QuizAttempt.vue'
                  ),
              },
            ],
          },
          {
            path: ':id/categories/:category_id/posts/:post_id/materials',
            name: 'post-materials',
            component: () =>
              import(
                /* webpackChunkName: "MobilePostMaterials" */ '../views/MobilePostMaterials.vue'
              ),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/quiz',
            name: 'quiz-overview',
            component: () =>
              import(/* webpackChunkName: "Quiz" */ '../views/Quiz.vue'),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/assignment',
            name: 'assignment-overview',
            component: () =>
              import(
                /* webpackChunkName: "Assignment" */ '../views/Assignment.vue'
              ),
          },
        ],
      },
      {
        path: '/upsell-offers/in-app/:id',
        name: 'in-app-upsell',
        component: () =>
          import(
            /* webpackChunkName: "UpsellCheckout" */ '../views/UpsellCheckout.vue'
          ),
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
      },
    ],
  },
  {
    path: '/offers/:id',
    name: 'offer',
    component: () =>
      import(/* webpackChunkName: "Checkout" */ '../views/Checkout.vue'),
  },
  {
    path: '/upsell-offers/one-click/:id',
    name: 'one-click-upsell',
    component: () =>
      import(
        /* webpackChunkName: "UpsellCheckout" */ '../views/UpsellCheckout.vue'
      ),
  },
  {
    path: '/courses/profile',
    name: 'ProfileContainer',
    component: ProfileContainer,
  },
  {
    path: '/courses/notifications',
    name: 'NotificationMobileView',
    component: NotificationMobileView,
  },
  {
    path: '/courses/home',
    name: 'LandingPage',
    component: DashBoard,
  },
  {
    path: '/404',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
]

const routesV2: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login-v2',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/courses/recovery',
    name: 'recovery-v2',
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */ '../views/PasswordRecovery.vue'
      ),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/courses/',
    component: SecureRoutes,
    children: [
      {
        path: 'library',
        name: 'library-old-v2',
        component: () =>
          import(/* webpackChunkName: "Library" */ '../views/Library.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'library-v2',
        name: 'library-v2-v2',
        component: () =>
          import(
            /* webpackChunkName: "NewLibrary" */ '../views/NewLibrary.vue'
          ),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'library-v2/my-courses',
        name: 'my-courses-library-v2',
        component: () =>
          import(
            /* webpackChunkName: "NewLibrary" */ '../views/NewLibrary.vue'
          ),
        props: {
          loadMyCourses: true,
        },
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'upsell-bundle/:offerId/:upsellId',
        name: 'upsell-bundle-v2',
        component: () =>
          import(
            /* webpackChunkName: "MobileUpsellBundleV2" */ '../views/mobile/MobileUpsellBundleV2.vue'
          ),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'settings',
        name: 'settings-v2',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '../views/settings/index.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'profile-settings-v2',
            component: () =>
              import(
                /* webpackChunkName: "Profile" */ '../views/settings/Profile.vue'
              ),
          },
          {
            path: 'password',
            name: 'password-settings-v2',
            component: () =>
              import(
                /* webpackChunkName: "Password" */ '../views/settings/Password.vue'
              ),
          },
          {
            path: 'purchase-history',
            name: 'purchase-history-v2',
            component: () =>
              import(
                /* webpackChunkName: "PurchaseHistory" */ '../views/settings/PurchaseHistory.vue'
              ),
          },
        ],
      },
      {
        path: 'products',
        component: () =>
          import(
            /* webpackChunkName: "ProductContainer" */ '../views/ProductContainer.vue'
          ),
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
        children: [
          {
            path: ':id',
            name: 'product-overview-v2',
            component: () =>
              import(
                /* webpackChunkName: "ProductsThemeController" */ '../views/ProductsThemeController.vue'
              ),
          },
          {
            path: ':id/categories',
            name: 'category-list-v2',
            component: () =>
              import(
                /* webpackChunkName: "CategoryListV2" */ '../views/mobile/CategoryListV2.vue'
              ),
          },
          {
            path: ':id/categories/:category_id',
            name: 'category-overview-v2',
            component: () =>
              import(
                /* webpackChunkName: "CategoryDetail" */ '../views/CategoryDetail.vue'
              ),
          },
          {
            path: ':id/categories/:category_id/posts/:post_id',
            name: 'post-overview-v2',
            component: () =>
              import(/* webpackChunkName: "Post" */ '../views/Post.vue'),
            meta: {
              breadcrumb: [
                { name: 'Home', link: '/courses/products/:id' },
                {
                  name: 'post',
                  link: '/courses/products/:id/categories/:category_id',
                },
              ],
            },
            children: [
              {
                path: 'comments',
                name: 'post-comments-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileComments" */ '../components/comments/MobileComments.vue'
                  ),
              },

              {
                path: 'mobile-quiz-overview',
                name: 'mobile-quiz-overview-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileAssessmentInitiate" */ '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },

              {
                path: 'mobile-quiz-overview/:quiz_id',
                name: 'mobile-quiz-questions-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileQuizV2" */ '../views/mobile/MobileQuizV2.vue'
                  ),
              },
              {
                path: 'quiz-result/:quiz_id/',
                name: 'mobile-quiz-result-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileQuizResult" */ '../components/assessments/MobileQuizResult.vue'
                  ),
              },
              {
                path: 'mobile-assignment',
                name: 'mobile-assignment-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileAssessmentInitiate" */ '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },
              {
                path: 'mobile-assignment-overview',
                name: 'mobile-assignment-overview-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "Assignment" */ '../views/Assignment.vue'
                  ),
              },
              {
                path: 'mobile-assignment-result',
                name: 'mobile-assignment-result-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "MobileAssignmentResult" */ '../components/assessments/MobileAssignmentResult.vue'
                  ),
              },
              {
                path: 'v2/assignment',
                name: 'new-assignment-overview-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "AssignmentAttempt" */ '../components/neo-classic-theme/assignment/AssignmentAttempt.vue'
                  ),
              },
              {
                path: 'v2/quiz',
                name: 'new-quiz-overview-v2',
                component: () =>
                  import(
                    /* webpackChunkName: "QuizAttempt" */ '@/components/neo-classic-theme/quiz/QuizAttempt.vue'
                  ),
              },
            ],
          },
          {
            path: ':id/categories/:category_id/posts/:post_id/materials',
            name: 'post-materials-v2',
            component: () =>
              import(
                /* webpackChunkName: "MobilePostMaterials" */ '../views/MobilePostMaterials.vue'
              ),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/quiz',
            name: 'quiz-overview-v2',
            component: () =>
              import(/* webpackChunkName: "Quiz" */ '../views/Quiz.vue'),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/assignment',
            name: 'assignment-overview-v2',
            component: () =>
              import(
                /* webpackChunkName: "Assignment" */ '../views/Assignment.vue'
              ),
          },
        ],
      },
      {
        path: 'upsell-offers/in-app/:id',
        name: 'in-app-upsell-v2',
        component: () =>
          import(
            /* webpackChunkName: "UpsellCheckout" */ '../views/UpsellCheckout.vue'
          ),
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
      },
    ],
  },
  {
    path: '/courses/offers/:id',
    name: 'offer-v2',
    component: () =>
      import(/* webpackChunkName: "Checkout" */ '../views/Checkout.vue'),
  },
  {
    path: '/courses/upsell-offers/one-click/:id',
    name: 'one-click-upsell-v2',
    component: () =>
      import(
        /* webpackChunkName: "UpsellCheckout" */ '../views/UpsellCheckout.vue'
      ),
  },
  {
    path: '/courses/profile',
    name: 'ProfileContainer-v2',
    component: ProfileContainer,
  },
  {
    path: '/courses/notifications',
    name: 'NotificationMobileView-v2',
    component: NotificationMobileView,
  },
  {
    path: '/courses/home',
    name: 'LandingPage-v2',
    component: DashBoard,
  },
  {
    path: '/courses/404',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
  { path: '/courses/:catchAll(.*)', redirect: '/404' },
]

const router = createRouter({
  history: createWebHistory(),
  routes: [...routes, ...routesV2],
})

async function loginWithLoginCode(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  try {
    let user, type
    const setPasswordPayload: any = {
      hostName: to.query.domain || window.location.hostname,
    }
    if (to.query.user) {
      user = to.query.user
      type = 'id'
      setPasswordPayload.userId = user
    }
    if (to.query.email) {
      user = getQueryString(window.location.search, 'email')
      type = 'email'
      setPasswordPayload.email = user
    }
    const { data: passwordData } = await LoginService.checkIfPasswordSet(
      setPasswordPayload
    )
    const passwordSet = passwordData.passwordSet
    const userId = passwordData.userId
    const email = passwordData.email || user // user variable gets email from search params
    if (!passwordSet) {
      if (inIframe()) {
        return next({
          name: 'set-password',
          query: {
            email: email.replace(/\+/g, '%2B'),
            loginCode: to.query.loginCode,
            userId: userId || user,
            type: 'new',
          },
        })
      }
      return redirectTo(
        'set-password',
        `email=${email.replace(/\+/g, '%2B')}&loginCode=${
          to.query.loginCode
        }&userId=${userId || user}&type=new`
      )
    }
    const { data } = await axios.post(
      `${config.newBaseUrl}/user/login-code/${to.query.loginCode}`,
      {
        user: user,
        type: type,
        deviceName: navigator.userAgent,
        deviceId: deviceId,
        deviceType: isMobileTablet() ? 'mobile' : 'desktop',
        hostName: to.query.domain || window.location.hostname,
      }
    )
    if (data.token) {
      const payload = btoa(JSON.stringify(data))
      setToken(payload)

      initialize(data.locationId)
      saveUserId(data.userId)
      saveContactId(data.contactId)
      saveFeatureFlag(data.featureFlag)
      const redirectUrl = getQueryString(window.location.search, 'redirectUrl')
      if (redirectUrl) {
        return window.open(redirectUrl, '_self')
      } else {
        return next({
          name: 'library-v2',
        })
      }
    }
  } catch (e) {
    console.error('Error ---->', e)
    if (isClientPortal()) return redirectTo('login')
    return next({
      name: 'login',
    })
  }
  return redirect(to, from, next)
}

function isValidLoginCodeRoute(route: RouteLocationNormalized) {
  return (
    route.query.loginCode &&
    (route.query.user || route.query.email) &&
    !(route.query.type || route.query.session)
  )
}

router.beforeEach(async (to, from, next) => {
  let cat
  let acat
  try {
    cat = await fetchCat()
    acat = await fetchAdminCat()
  } catch (error) {
    cat = undefined
  }
  const externalUserId =
    (cat ? cat.external_user_id : null) || (cat ? cat.externalUserId : null)
  const enableClientPortal = isClientPortal() && externalUserId

  // Overwriting old cat to login user with login code.
  if (cat && cat.userId && isValidLoginCodeRoute(to) && inIframe()) {
    // Clearing out old tokens
    removeAllTokens()
    return await loginWithLoginCode(to, from, next)
  }

  if (to.matched.some((record) => record.meta.requiresGuest)) {
    if ((cat && cat.userId) || enableClientPortal) {
      return next({
        name: 'library-v2',
      })
    } else if (isValidLoginCodeRoute(to)) {
      return await loginWithLoginCode(to, from, next)
    }
  } else if (to.matched.some((record) => record.meta.adminUser)) {
    const { location_id: locationId, token } = to.query
    // need to handle for checkouts from client portal side
    if (
      !token &&
      !window.location.href.includes('source=communities') &&
      !acat &&
      !cat
    ) {
      return redirectTo('login')
    }
    if (token) {
      const { data } = await axios.post(
        config.newBaseUrl + '/user/login-token',
        {
          locationId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      await firebase.auth().signInWithCustomToken(data.token)

      const payload = btoa(JSON.stringify(data))
      setAdminToken(payload)
    }
    return redirect(to, from, next)
  } else if (
    to.matched.some((record) => record.meta.clientAuth) &&
    to.query.token
  ) {
    const { location_id: locationId, token } = to.query

    // need to handle for library and upsell bundle page in client portal
    if (token) {
      await axios
        .post(config.newBaseUrl + '/user/login-ctoken', {
          locationId,
          ct: token,
        })
        .then(async ({ data }) => {
          await firebase.auth().signInWithCustomToken(data.token)

          const payload = btoa(JSON.stringify(data))
          setToken(payload)
        })
        .catch(() => {
          if (isClientPortal()) return redirectTo('login')
          next({
            name: 'login',
          })
        })
    }
    return redirect(to, from, next)
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!cat && !acat) {
      if (isClientPortal()) return redirectTo('login')
      return next({
        name: 'login',
      })
    }
    return redirect(to, from, next)
  }
  if (isClientPortal()) return redirect(to, from, next)
  else next()
})

export default router
