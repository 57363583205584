import { TYPE_TO_EXTENSION } from './constants'
import { replaceBucketUrlWithCdnUrl } from './filter'

export async function downloadFile(givenUrl: string, title?: string) {
  await fetch(replaceBucketUrlWithCdnUrl(givenUrl), {
    method: 'GET',
  })
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const downloadTrigger = document.createElement('a')
      downloadTrigger.href = url

      // Using last segment of url for title
      downloadTrigger.download =
        title || getFileNameFromUrl(givenUrl) || new Date().getTime().toString()
      downloadTrigger.click()
      window.URL.revokeObjectURL(url)
    })
}

function getFileNameFromUrl(url: string): string {
  const urlPath = url.split('/')
  return urlPath.length ? urlPath[urlPath.length - 1] : ''
}

/**
 * Downloads a file from the given URL.
 * @param {string} url - The URL of the file to download.
 * @param {string} [givenFileName] - (Optional) The name of the downloaded file. If not provided, the current timestamp will be used.
 * @param {function} [progressUpdate] - (Optional) A progressUpdate function that receives the progress of the download as a percentage number.
 * @param {function} [onCancel] - (Optional) A function that will be called when the download is cancelled.
 */
export function downloadFileWithProgress({
  url,
  givenFileName,
  progressUpdate,
  onCancel,
}: {
  url: string
  givenFileName?: string
  progressUpdate?: (progress: number) => void
  onCancel?: () => void
}) {
  try {
    const xmlHTTP = new XMLHttpRequest()
    const downloadPromise = new Promise((resolve, reject) => {
      let blob = null
      xmlHTTP.open('GET', url, true)
      xmlHTTP.responseType = 'blob'
      xmlHTTP.addEventListener('progress', (progress) => {
        progressUpdate((progress.loaded / progress.total) * 100)
      })
      xmlHTTP.addEventListener('loadend', function () {
        const fileName =
          givenFileName ||
          getFileNameFromUrl(url) ||
          new Date().getTime().toString()

        if (xmlHTTP.status === 200) {
          blob = xmlHTTP.response
          const tempEl = document.createElement('a')
          const objectUrl = window.URL.createObjectURL(blob)
          tempEl.style.display = 'none'
          tempEl.href = objectUrl
          tempEl.download = fileName
          tempEl.click()
          window.URL.revokeObjectURL(objectUrl)
        } else {
          console.error(
            'Failed to download file:',
            xmlHTTP.status,
            xmlHTTP.statusText
          )
        }
        resolve({
          fileName,
          blob,
        })
      })
      // Need to handle pause while downloading and cancelling
      xmlHTTP.addEventListener('abort', reject)
      xmlHTTP.addEventListener('error', reject)
      xmlHTTP.send()

      return blob
    })

    const cancelDownload = () => {
      xmlHTTP.abort()
      if (onCancel) {
        onCancel()
      }
    }

    return { downloadPromise, cancelDownload }
  } catch (error) {
    console.error(`Error downloading file: ${error}`)
  }
}

export function humanReadableFileSize(size): string {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }`
}

export const get1DigitRandomNumber = (): number => {
  return Math.floor(1 + Math.random() * 9)
}

export function findExtensionFromFileType(fileType: string): string {
  if (fileType === null || fileType === undefined) return ''

  /**
   * fileType is a string of format: mainType/subType
   */
  const splitedFileType = fileType.split('/')

  const mainType = splitedFileType[0]
  const subType = splitedFileType[1]

  if (mainType === undefined || subType === undefined) {
    return ''
  }

  if (TYPE_TO_EXTENSION[mainType] === undefined) return ''
  if (TYPE_TO_EXTENSION[mainType][subType] === undefined) {
    return TYPE_TO_EXTENSION[mainType]['default']
  } else {
    return TYPE_TO_EXTENSION[mainType][subType]
  }
}
