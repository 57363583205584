<template>
  <div class="font-sans">
    <div id="user-library" class="w-full h-screen">
      <div class="bg-gray-50 flex flex-col overflow-y-auto min-h-screen">
        <Navigation
          @changeActive="changeActive"
          :coursesTypes="coursesTypes"
          :active="active"
          class="mb-5"
        />
        <div
          class="w-full flex items-center justify-center courses-font-family font-semibold mt-20"
          v-if="isPortalActive && !fetching && !allCourses.length"
        >
          <UIEmpty
            id="empty-product-message"
            title="No courses found"
            description="You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey."
          />
        </div>
        <div
          v-else
          id="library-container"
          class="w-11/12 flex-1 flex flex-col xl:w-11/12 mx-auto mt-4 content-fix-width"
        >
          <div
            v-if="!isPortalActive"
            id="library-title"
            class="pb-2 w-fit text-left relative border-b border-slate-200 bg-transparent"
            style="background-color: transparent !important"
          >
            <span
              class="font-semibold text-md p-2 cursor-pointer"
              :class="
                active === coursesTypes.allCourses
                  ? ' text-blue-600 '
                  : 'text-slate-600 '
              "
              @click="changeActive(coursesTypes.allCourses)"
              >All Courses
              <span
                v-if="fetching"
                class="px-2 py-1 rounded font-medium animate-pulse"
                :class="
                  active === coursesTypes.allCourses
                    ? '  bg-blue-100'
                    : ' bg-slate-200'
                "
                ><span class="">00</span></span
              >
              <span
                v-else
                class="px-2 py-1 rounded font-medium"
                :class="
                  active === coursesTypes.allCourses
                    ? ' text-blue-600 bg-blue-100'
                    : 'text-slate-600 bg-slate-200'
                "
                >{{ allCourses.length }}</span
              >
            </span>
            <span
              class="font-semibold text-md p-2 cursor-pointer ml-4"
              :class="
                active === coursesTypes.myCourses
                  ? ' text-blue-600'
                  : 'text-slate-600'
              "
              @click="changeActive(coursesTypes.myCourses)"
              >My Courses
              <span
                v-if="fetching"
                class="px-2 py-1 rounded font-medium animate-pulse"
                :class="
                  active === coursesTypes.allCourses
                    ? '  bg-blue-100'
                    : ' bg-slate-200'
                "
                ><span class="">00</span></span
              >
              <span
                v-else
                class="px-2 py-1 rounded font-medium"
                :class="
                  active === coursesTypes.myCourses
                    ? ' text-blue-600 bg-blue-100'
                    : 'text-slate-600 bg-slate-200'
                "
                >{{ products.length }}</span
              >
            </span>
            <span
              class="absolute bottom-0 h-0.5 left-0 bg-blue-600 transition-all duration-300"
              :style="{
                width: activeTabWidth + 'px',
                transform: 'translateX(' + activeTabLeft + 'px)',
              }"
            ></span>
          </div>

          <LibraryShimmer class="pt-20" v-if="fetching" />

          <transition name="fade" mode="out-in">
            <div
              v-if="active === coursesTypes.allCourses && !fetching"
              :key="'all-courses'"
              id="product-list"
              class="my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8"
              :class="[isPortalActive ? 'pt-20' : '']"
            >
              <template :key="course.id" v-for="course in allCourses">
                <NeoUpsellBundle
                  class="responsive-card cursor-pointer"
                  v-if="course.upsellType && course.offerUpSell"
                  :offer="course.offerUpSell"
                  :upsellId="course.id"
                  :purchasedProductIds="purchasedProductIds"
                />
                <NeoProductCard
                  v-else
                  class="responsive-card cursor-pointer"
                  :product-id="course.id"
                  :product-title="course.title"
                  :poster-image="course.posterImage"
                  :product-description="course.description"
                  :instructor-name="
                    course.customizations &&
                    course.customizations.instructorName
                  "
                  :instructor-headshot="
                    course.customizations &&
                    course.customizations.instructorHeadshot
                  "
                  :show-in-library="true"
                  label="in-library"
                  :progress="getCourseProgress(course)"
                  @handleClick="openProduct(course.id)"
                />
              </template>
            </div>
            <div
              v-else-if="active === coursesTypes.myCourses && !fetching"
              :key="'my-courses'"
              id="product-list"
              class="my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8"
              :class="[isPortalActive ? 'pt-20' : '']"
            >
              <NeoProductCard
                v-for="product in products"
                :key="product.id"
                class="responsive-card cursor-pointer"
                :product-id="product.id"
                :product-title="product.title"
                :poster-image="product.posterImage"
                :product-description="product.description"
                :instructor-name="
                  product.customizations &&
                  product.customizations.instructorName
                "
                :instructor-headshot="
                  product.customizations &&
                  product.customizations.instructorHeadshot
                "
                :progress="getCourseProgress(product)"
                @handleClick="openProduct(product.id)"
              />
            </div>
          </transition>
        </div>
      </div>
      <UniversalSearch
        :show-modal="showSearchModal"
        @close="showSearchModal = false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import LibraryShimmer from '@/components/common/LibraryShimmer.vue'
import Navigation from '@/components/common/Navigation.vue'
import UniversalSearch from '@/components/common/UniversalSearch.vue'
import NeoProductCard from '@/components/product/NeoProductCard.vue'
import NeoUpsellBundle from '@/components/product/NeoUpsellBundle.vue'
import { isClientPortal } from '@/helper'
import { LIBRARY_MENU_ITEMS } from '@/helper/constants'
import UpsellOffer from '@/models/UpsellOffer'
import { UserPurchaseService } from '@/services'
import { UIEmpty } from '@gohighlevel/ghl-ui'
import { defineComponent, nextTick, ref } from 'vue'
import Product from '../models/Product'

export default defineComponent({
  props: {
    loadMyCourses: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NeoUpsellBundle,
    Navigation,
    NeoProductCard,
    LibraryShimmer,
    UniversalSearch,
    UIEmpty,
  },
  data() {
    return {
      products: [] as Product[],
      fetching: false,
      showSearchModal: false,
      upsellOffers: [] as UpsellOffer[],
      allCourses: [],
      purchasedProductIds: [],
      coursesTypes: {
        allCourses: LIBRARY_MENU_ITEMS.allCourses,
        myCourses: LIBRARY_MENU_ITEMS.myCourses,
      },
      active: this.$route.query.courses
        ? this.$route.query.courses === 'all'
          ? LIBRARY_MENU_ITEMS.allCourses
          : LIBRARY_MENU_ITEMS.myCourses
        : LIBRARY_MENU_ITEMS.allCourses,
      activeTabLeft: ref(0),
      activeTabWidth: ref(0),
    }
  },
  async mounted() {
    this.emitter.on('openSearchModal', this.openSearchModal)
    await this.fetchData()
    if (this.loadMyCourses) {
      this.updateActiveLibrary(this.coursesTypes.myCourses)
    }
  },
  computed: {
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isPortalActive() {
      return isClientPortal()
    },
    locationId(): string {
      return this.$store.getters.locationId
    },
  },
  methods: {
    /**
     * A function that fetches user products and upsell bundle.
     * Upsell bundle function is dependant on user products.
     * @return {Promise<void>} Promise that resolves after fetching user products and upsell bundle.
     */
    async fetchData(): Promise<void> {
      try {
        this.fetching = true
        await this.fetchUserProducts()
        await this.fetchUpsellBundle()
      } catch (error) {
        console.error('Error while fetching data --> ', error)
      } finally {
        this.fetching = false
      }
    },
    async fetchUserProducts() {
      try {
        this.products = await UserPurchaseService.getAllProducts({
          getPostCount: true,
        })
        this.purchasedProductIds = this.products.map((p) => p.id)
      } catch (error) {
        console.error('Error while fetching products --> ', error)
      }
    },
    async fetchUpsellBundle() {
      try {
        const upsellOffers = await UserPurchaseService.getBundleOffers()
        const set = new Set()
        this.upsellOffers = upsellOffers.filter((offer) => {
          if (!set.has(offer.upsellOfferId)) {
            set.add(offer.upsellOfferId)
            return true
          }
          return false
        })
        this.allCourses = [...this.upsellOffers, ...this.products]
      } catch (error) {
        console.error('Error while fetching upsell bundles --> ', error)
      }
    },
    changeActive(value: string) {
      this.active = value
      this.updateActiveTabIndicator()
    },
    updateActiveLibrary(val: string) {
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', val)
      window.scrollTo(0, 0)
    },
    openProduct(productId) {
      this.$router.push({
        name: 'product-overview',
        params: { id: productId },
        query: this.$route.query,
      })
    },
    openSearchModal(value: boolean) {
      this.showSearchModal = value
    },
    async updateActiveTabIndicator() {
      await nextTick()
      const activeTab = document.querySelector(
        `#library-title span:nth-child(${
          this.active === 'All Courses' ? 1 : 2
        })`
      ) as HTMLElement
      if (activeTab) {
        this.activeTabLeft = activeTab.offsetLeft
        this.activeTabWidth = activeTab.offsetWidth
      }
    },
    getCourseProgress(course: any) {
      const completedPosts =
        course.noOfPostCompleted || course._data.noOfPostCompleted || 0
      const totalPosts = course.totalPosts || course._data.totalPosts || 0
      return (completedPosts / totalPosts) * 100 || 0
    },
  },
  watch: {
    active: {
      handler: 'updateActiveTabIndicator',
      immediate: true,
    },
    loadMyCourses(newValue) {
      if (newValue) this.updateActiveLibrary(this.coursesTypes.myCourses)
      else this.updateActiveLibrary(this.coursesTypes.allCourses)
    },
  },
})
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.responsive-card {
  height: 32rem;
  max-height: 40rem;
}

@media (min-width: 1025px) and (max-width: 1280px) { /* Small Desktops and Laptops */
  .responsive-card {
    height: 28rem;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) { /* Large Desktops and High-Resolution Screens */
  .responsive-card {
    height: 30rem;
  }
}

@media (min-width: 1441px) { /* Extra-Large Desktops */
  .responsive-card {
    height: 32rem;
  }
}

@media (max-width: 1024px) {
  .responsive-card {
    height: 24rem;
  }
}

@media (max-width: 600px) {
  .responsive-card {
    height: 20rem;
  }
}

@media (max-width: 480px) {
  .responsive-card {
    height: 16rem;
  }
}
</style>
