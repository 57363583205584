import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "offer-amount d-block lg:px-3 text-center",
  style: { color: '#FFBC00' }
}
const _hoisted_3 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_4 = { class: "flex items-center justify-between" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "offer-amount lg:px-3 text-center inline-flex items-center",
  style: { color: '#FFBC00' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Title = _resolveComponent("Title")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleClick'))),
    class: _normalizeClass(["border-2 grid shadow-sm bg-white p-4 rounded-lg w-full overflow-hidden cursor-pointer hover:shadow-lg hover:-translate-y-1 transform transition duration-200", [
      _ctx.sliderCard ? '' : 'lg:m-0',
      _ctx.activeProduct && _ctx.activeProduct === _ctx.productId
        ? 'border-blue-500'
        : 'border-slate-200',
      !_ctx.upsellProduct ? 'ml-0' : '',
      _ctx.newMobileScreens ? '' : 'border',
    ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full h-auto relative rounded-lg", [_ctx.sliderCard && 'product-modal-image']])
    }, [
      _createElementVNode("img", {
        class: "w-full aspect-video object-cover rounded-lg",
        src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.posterImage) || _ctx.defaultEmptyImage,
        alt: "Product Poster Image"
      }, null, 8, _hoisted_1),
      (_ctx.newMobileScreens)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["absolute text-xs rounded text-white right-0 bottom-0 mr-2 mb-2 z-1", _ctx.upsellProduct ? 'hidden' : 'lg:hidden'])
          }, [
            (_ctx.label && _ctx.label === 'in-library')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["offer-amount px-1 rounded text-white", [
            _ctx.upsellProduct ? 'text-xxs' : 'text-xs',
            _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : '',
          ]])
                }, " In Library ", 2))
              : _createCommentVNode("", true),
            (_ctx.label && _ctx.label === 'locked')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_BaseIcon, {
                    name: "lockfilled",
                    hwClass: "w-4 h-4"
                  })
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["lg:mt-4", [
        _ctx.newMobileScreens ? '' : 'h-36',
        {
          'h-28 md:h-32 lg:h-36 py-2': _ctx.sliderCard,
          'h-24 md:h-24': _ctx.upsellProduct,
        },
      ]])
    }, [
      _createVNode(_component_Title, {
        tag: "h4",
        id: "product-card-title",
        class: _normalizeClass(["text-base tracking-wide text-black font-medium", 
          _ctx.upsellProduct || _ctx.sliderCard || _ctx.newMobileScreens
            ? 'text-sm'
            : 'text-lg'
        ])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.truncateText(_ctx.productTitle)), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.productDescription && !_ctx.newMobileScreens)
        ? (_openBlock(), _createBlock(_component_Paragraph, {
            key: 0,
            class: _normalizeClass(["text-gray-700 mt-2 leading-normal font-normal", _ctx.upsellProduct ? 'text-xs' : 'text-sm']),
            maxLength: 150,
            mode: "none"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.productDescription || ''), 1)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ProgressBar, {
            progress: Math.round(_ctx.progress),
            maxCap: 100
          }, null, 8, ["progress"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.newMobileScreens || _ctx.upsellProduct || _ctx.sliderCard)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["w-full", ['pt-1', _ctx.upsellProduct && 'border-gray-200']])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                id: "instructor-headshot",
                class: _normalizeClass([_ctx.instructorHeadshot ? 'border' : '', "w-12 h-12 aspect-square object-cover rounded-lg"]),
                alt: "Instructor Headshot",
                src: 
              _ctx.replaceBucketUrlWithCdnUrl(_ctx.instructorHeadshot) || _ctx.userAvatarImage
            
              }, null, 10, _hoisted_6),
              _createElementVNode("div", {
                id: "instructor-name",
                class: _normalizeClass(["font-semibold text-black", _ctx.upsellProduct ? 'text-xxs px-1' : 'text-sm px-2'])
              }, _toDisplayString(_ctx.truncateText(
                _ctx.instructorName || 'Instructor Name',
                _ctx.instructorNameLimit
              )), 3)
            ]),
            (
            _ctx.label &&
            _ctx.label === 'in-library' &&
            (!_ctx.upsellProduct || !_ctx.newMobileScreens || _ctx.sliderCard)
          )
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 0,
                  id: "product-card-button",
                  variant: "success",
                  size: "medium",
                  class: _normalizeClass([
            _ctx.upsellProduct ? 'text-xxs px-1' : '',
            _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : '',
          ])
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" In Library ")
                  ])),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true),
            (
            _ctx.label && _ctx.label === 'in-library' && _ctx.upsellProduct && _ctx.newMobileScreens
          )
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(["offer-amount py-1 rounded text-white inline-flex items-center", [
            _ctx.upsellProduct ? 'text-xxs px-1' : 'text-sm px-2',
            _ctx.newMobileScreens ? _ctx.getMobileTheme.primaryText : 'bg-in-library',
          ]])
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: "book",
                    hwClass: "w-4 h-4"
                  })
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.label && _ctx.label === 'locked')
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createVNode(_component_BaseIcon, {
                    name: "lockfilled",
                    hwClass: "w-4 h-4"
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}