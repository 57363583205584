<template>
  <div
    @click="$emit('handleClick')"
    class="border-2 grid shadow-sm bg-white p-4 rounded-lg w-full overflow-hidden cursor-pointer hover:shadow-lg hover:-translate-y-1 transform transition duration-200"
    :class="[
      sliderCard ? '' : 'lg:m-0',
      activeProduct && activeProduct === productId
        ? 'border-blue-500'
        : 'border-slate-200',
      !upsellProduct ? 'ml-0' : '',
      newMobileScreens ? '' : 'border',
    ]"
  >
    <div
      class="w-full h-auto relative rounded-lg"
      :class="[sliderCard && 'product-modal-image']"
    >
      <!-- move it inside -->
      <img
        class="w-full aspect-video object-cover rounded-lg"
        :src="replaceBucketUrlWithCdnUrl(posterImage) || defaultEmptyImage"
        alt="Product Poster Image"
      />
      <div
        v-if="newMobileScreens"
        class="absolute text-xs rounded text-white right-0 bottom-0 mr-2 mb-2 z-1"
        :class="upsellProduct ? 'hidden' : 'lg:hidden'"
      >
        <span
          v-if="label && label === 'in-library'"
          class="offer-amount px-1 rounded text-white"
          :class="[
            upsellProduct ? 'text-xxs' : 'text-xs',
            newMobileScreens ? getMobileTheme.primary : '',
          ]"
        >
          In Library
        </span>
        <span
          v-if="label && label === 'locked'"
          class="offer-amount d-block lg:px-3 text-center"
          :style="{ color: '#FFBC00' }"
        >
          <BaseIcon name="lockfilled" hwClass="w-4 h-4" />
        </span>
      </div>
    </div>
    <div
      class="lg:mt-4"
      :class="[
        newMobileScreens ? '' : 'h-36',
        {
          'h-28 md:h-32 lg:h-36 py-2': sliderCard,
          'h-24 md:h-24': upsellProduct,
        },
      ]"
    >
      <Title
        tag="h4"
        id="product-card-title"
        class="text-base tracking-wide text-black font-medium"
        :class="
          upsellProduct || sliderCard || newMobileScreens
            ? 'text-sm'
            : 'text-lg'
        "
      >
        {{ truncateText(productTitle) }}
      </Title>
      <Paragraph
        class="text-gray-700 mt-2 leading-normal font-normal"
        :maxLength="150"
        mode="none"
        :class="upsellProduct ? 'text-xs' : 'text-sm'"
        v-if="productDescription && !newMobileScreens"
      >
        {{ productDescription || '' }}
      </Paragraph>
    </div>

    <div v-if="showProgress" class="mb-2">
      <ProgressBar :progress="Math.round(progress)" :maxCap="100" />
    </div>

    <div
      v-if="!newMobileScreens || upsellProduct || sliderCard"
      class="w-full"
      :class="['pt-1', upsellProduct && 'border-gray-200']"
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <!-- move it inside the NewProductCard component -->
          <img
            id="instructor-headshot"
            :class="instructorHeadshot ? 'border' : ''"
            class="w-12 h-12 aspect-square object-cover rounded-lg"
            alt="Instructor Headshot"
            :src="
              replaceBucketUrlWithCdnUrl(instructorHeadshot) || userAvatarImage
            "
          />
          <div
            id="instructor-name"
            class="font-semibold text-black"
            :class="upsellProduct ? 'text-xxs px-1' : 'text-sm px-2'"
          >
            {{
              truncateText(
                instructorName || 'Instructor Name',
                instructorNameLimit
              )
            }}
          </div>
        </div>

        <Badge
          id="product-card-button"
          variant="success"
          size="medium"
          v-if="
            label &&
            label === 'in-library' &&
            (!upsellProduct || !newMobileScreens || sliderCard)
          "
          :class="[
            upsellProduct ? 'text-xxs px-1' : '',
            newMobileScreens ? getMobileTheme.primary : '',
          ]"
        >
          In Library
        </Badge>
        <span
          v-if="
            label && label === 'in-library' && upsellProduct && newMobileScreens
          "
          class="offer-amount py-1 rounded text-white inline-flex items-center"
          :class="[
            upsellProduct ? 'text-xxs px-1' : 'text-sm px-2',
            newMobileScreens ? getMobileTheme.primaryText : 'bg-in-library',
          ]"
        >
          <BaseIcon name="book" hwClass="w-4 h-4" />
        </span>
        <span
          v-if="label && label === 'locked'"
          class="offer-amount lg:px-3 text-center inline-flex items-center"
          :style="{ color: '#FFBC00' }"
        >
          <BaseIcon name="lockfilled" hwClass="w-4 h-4" />
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  userAvatarImage,
  INSTRUCTOR_NAME_MAX_LENGTH,
  defaultEmptyImage,
} from '../../helper/constants'
import { getMobileTheme } from '../../helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import ProgressBar from '@/components/common/ProgressBar.vue'
import Image from '@/components/neo-classic-theme/common/Image.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Badge from '@/components/neo-classic-theme/common/Badge.vue'

export default defineComponent({
  components: {
    BaseIcon,
    ProgressBar,
    Paragraph,
    Button,
    Image,
    Badge,
    Title,
  },
  props: {
    productId: String,
    productTitle: String,
    posterImage: String,
    productDescription: String,
    instructorName: String,
    instructorHeadshot: String,
    activeProduct: String,
    upsellProduct: {
      type: Boolean,
      default: false,
    },
    sliderCard: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    showProgress: {
      type: Boolean,
      default: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      userAvatarImage,
      // defaultPosterImage,
      instructorNameLimit: INSTRUCTOR_NAME_MAX_LENGTH,
      defaultEmptyImage,
    }
  },
  computed: {
    newMobileScreens() {
      return this.$store.getters.newMobileScreens
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
})
</script>
<style scoped>
.product-title {
  word-break: break-word;
}

.product-image > img {
  aspect-ratio: 16/9;
  object-fit: contain;
}

#instructor-headshot > img {
  aspect-ratio: 1;
}
</style>
